.message {
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  display: flex;
  justify-content: space-between;
}
.message_card {
  width: 70%;
  height: 300px;
  padding: 0 4rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8rem;
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
}
.gold {
  background: #deb05f;
}
.blue {
  background: #17838d;
}

.card_title {
  color: white;
  font-size: 6rem;
  font-weight: 700;
  padding-right: 7rem;
}
.card_icon svg {
  fill: #fff;
  stroke: #fff;
}
.card_text {
  color: #17838d;
  font-size: 1.7rem;
  font-weight: 600;
  background-color: #fff;
  height: 70%;
  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  position: absolute;
  left: -15rem;
}
.gold .card_text {
  width: 70%;
}
.blue .card_text {
  left: -30%;
}

.message_pagination {
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 2rem;
  height: 100%;
  padding: 1rem;
}
.message_pagination button {
  background: transparent;
  border: 1px solid #333;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #333;
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1270px) {
  .message_card {
    gap: 5rem;
  }
  .card_text {
    left: -20rem;
  }
}
@media (max-width: 1130px) {
  .message_card {
    gap: 4rem;
  }
  .card_text {
    width: 40rem;
  }
}
@media (max-width: 970px) {
  .message_card {
    gap: 3rem;
  }
  .card_text {
    left: -15rem;
  }
  .card_title {
    padding-right: 5rem;
  }
}
@media (max-width: 820px) {
  .message_card {
    gap: 3rem;
  }
  .card_text {
    left: -15rem;
  }
}
@media (max-width: 800px) {
  .card_title {
    padding-right: 0rem;
  }
}

@media (max-width: 750px) {
  .message_card {
    gap: 3rem;
    width: 80%;
    flex-direction: column;
    height: auto;
  }
  .card_text {
    position: static;
    height: 100%;
    width: auto;
    background-color: transparent;
    color: #fff;
    padding: 0;
    text-align: center;
  }
}
@media (max-width: 420px) {
  .message_card {
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    height: auto;
  }
  .message {
    flex-direction: column;
  }
  .message_pagination {
    align-self: center;
  }
  .card_text {
    padding-right: 0;
    text-align: center;
  }
}
