.slider-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--parent-background); */
  padding-top: 1rem;
  box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: -2px -10px 3px -6px rgba(0, 0, 0, 0.75) inset;
  padding-bottom: 1rem;
}
.swiper {
  width: 100%;
  padding: 0;
  margin: 0;
}
.slider {
  width: 100%;
}
.swiper-wrapper {
  padding: 0;
}
.swiper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}
@media (max-width: 380px) {
  .slider {
    width: 100%;
    padding-bottom: 0.8rem;
  }
}
.container_content {
  position: absolute;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 10%;
  left: 0;
  width: 100%;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.banner-center-box img {
  width: 40rem;
  height: 40rem;
}

@media (max-width: 760px) {
  .banner-parallax .container,
  .banner-parallax .row {
    width: 100%;
  }
  .banner-center-box img {
    width: 20rem;
    height: 20rem;
  }
}

@media (max-width: 550px) {
  .banner-parallax .container,
  .banner-parallax .row {
    width: 100%;
  }
  .banner-center-box {
    width: 100%;
  }
  .banner-center-box img {
    width: 10rem;
    height: 10rem;
  }
}
.swiper-button-next,
.swiper-button-prev {
  color: #d8a44e;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 2rem;
}

.swiper-pagination-bullet-active {
  background: #17838d;
}
